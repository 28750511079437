@import 'tailwindcss/base';

a {
  @apply text-blue-700;
}

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

.content p {
  @apply my-6;
}

.content ul {
  @apply my-6;
}
